import Api from "../api/api";

export const getPortfolioValue = async (folioNumber: number) => {
  var route = "dashboard/PortfolioValue/" + folioNumber;
  var Data = await Api(route, null, "GET");
  return Data;
};

export const getInvestmentSummary = async (folioNumber: number) => {
  var route =
    `dashboard/InvestmentSummary${process.env.REACT_APP_CLOUD ?? ""}/` +
    folioNumber;
  var Data = await Api(route, null, "GET");
  return Data;
};

export const getCategoryWiseBreakup = async (folioNumber: number) => {
  var route =
    `dashboard/CategoryWiseBreakup${process.env.REACT_APP_CLOUD ?? ""}/` +
    folioNumber;
  var Data = await Api(route, null, "GET");
  return Data;
};

export const getRiskWiseBreakup = async (folioNumber: number) => {
  var route =
    `dashboard/RiskWiseBreakup${process.env.REACT_APP_CLOUD ?? ""}/` +
    folioNumber;
  var Data = await Api(route, null, "GET");
  return Data;
};

export const getFundWiseSummary = async (folioNumber: number) => {
  var route =
    `dashboard/FundWiseSummary${process.env.REACT_APP_CLOUD ?? ""}/` +
    folioNumber;
  var Data = await Api(route, null, "GET");
  return Data;
};
