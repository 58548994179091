import Api from "../api/api";

export const getFunds = async () => {
  var route =
    "fund" +
    (process.env.REACT_APP_CLOUD ? "/" + process.env.REACT_APP_CLOUD : "");
  var Data = await Api(route, null, "GET");
  return Data;
};

export const getFundsByAcctCategory = async (accountCategoryID: number) => {
  var route =
    "fund" +
    (process.env.REACT_APP_CLOUD ? "/" + process.env.REACT_APP_CLOUD : "") +
    "/" +
    accountCategoryID;
  var Data = await Api(route, null, "GET");
  return Data;
};

export const getFundsWithBanks = async () => {
  var route =
    "fund/banks" +
    (process.env.REACT_APP_CLOUD ? "/" + process.env.REACT_APP_CLOUD : "");
  var Data = await Api(route, null, "GET");
  return Data;
};
