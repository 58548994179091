import { useEffect, useState } from "react";
import ButtonDX from "../../components/controls/buttondx";
import GridDX from "../../components/layout/griddx";
import { useAuthContext } from "../../context/authcontext";
import { useErrorContext } from "../../context/errorcontext";
import { getAccountStatement } from "../../shared/services/reportservices";
import { Document, Page, pdfjs } from "react-pdf";
import { useConfigContext } from "../../context/configcontext";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

const AccountSummary = () => {
  const { setError, setInfo } = useErrorContext();
  const { getUserDetails } = useAuthContext();
  const [folioNumber, setFolioNumber] = useState("");
  const [reportData, setReportData] = useState("");
  const [showDoc, setShowDoc] = useState(false);

  const { DATE_FORMAT } = useConfigContext();

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    getUserDetails()
      .then((userDetails: any) => {
        setFolioNumber(userDetails.currentFolioNumber);
        fetchStatement(userDetails.currentFolioNumber.toString());
      })
      .catch((err: any) => setError(err));
  }, []);

  const fetchStatement = async (folioNo: string) => {
    let request = {
      folioNumber: folioNo,
    };
    getAccountStatement(request).then((response) => {
      console.log("account statement response", response);

      setReportData(response.reportData);
      setShowDoc(true);
    });
  };

  const base64toBlob = (data: string) => {
    const bytes = atob(data);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: "application/pdf" });
  };

  const onDownload = () => {
    // `base64String` is the given base 64 data
    const blob = base64toBlob(reportData);
    const url = URL.createObjectURL(blob);
    let alink = document.createElement("a");
    alink.href = url;
    alink.download =
      "Account Statement" + moment(Date()).format(DATE_FORMAT) + ".pdf";
    alink.click();
  };

  const renderDocument = () => {
    const documentToView = "data:application/pdf;base64," + reportData;
    console.log("rendering doc", documentToView);
    return (
      <Document file={`${documentToView}`}>
        <Page pageNumber={1} renderTextLayer={false} />
      </Document>
    );
  };

  return (
    <GridDX container sx={{ width: "100%" }}>
      {!showDoc ? (
        <Skeleton
          containerClassName="skeleton-container"
          style={{ height: 350, marginBottom: 8, marginTop: 8 }}
        />
      ) : (
        <>
          <GridDX
            item
            xs={12}
            spacing={2}
            sx={{ justifyContent: "flex-end", mt: 2 }}
          >
            <GridDX item>
              <ButtonDX
                style={{ width: 125, height: 36.5 }}
                onClick={onDownload}
              >
                Download
              </ButtonDX>
            </GridDX>
          </GridDX>
          <GridDX
            item
            xs={12}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              mr: 4,
              ml: 4,
              mt: 4,
              mb: 4,
            }}
          >
            {showDoc && reportData && renderDocument()}
          </GridDX>
        </>
      )}
    </GridDX>
  );
};

export default AccountSummary;
