export const onboardingData = {
  userApplicationId: 0,
  userId: 0,

  accountCategoryId: { id: 1, value: "GENERAL" },
  fundPreferenceId: null,
  vpsFundId: null,
  name: "",
  fatherHusbandName: "",
  genderId: null,
  dateOfBirth: null,
  motherMaidenName: "",
  cnic: "",
  cnicIssueDate: null,
  cnicExpiryDate: null,
  isCNICLifeTime: false,
  contactOwnershipId: null,
  expectedRetirementAge: null,
  expectedRetirementDate: null,

  residentialAddress: "",
  isResidentAdressSameAsCNIC: true, // IMPORTANT
  mailingAddress: "",
  mailingSameAsResidential: false,
  residentialStatusId: null,
  countryOfResidenceId: null,
  cityOfResidenceId: null,
  areaId: null,
  nationalityId: null,
  countryOfBirthId: null,
  cityOfBirthId: null,
  isPoliticallyExposedPerson: false,
  isUSResident: false,
  isNonPakTaxResident: false,
  isZakatDeduction: true, // IMPORTANT
  isNonMuslim: "false", // IMPORTANT

  userBankId: 0,
  bankId: null,
  ibanNumber: "",
  isIBANVerified: false,
  oneLinkTitle: "",
  useOnlineBanking: false,

  educationId: null,
  professionId: null,
  occupationId: null,
  annualIncomeId: null,
  sourceOfIncome: [0], // IMPORTANT
  nextOfKinName: null,
  nextOfKinCNIC: null,
  nextOfKinRwc: null,
  nextOfKinMobileNumber: null,
  nomineeShare: null,

  isHeadOfState: false,
  isHeadOfGovt: false,
  isSeniorPolitician: false,
  isSeniorGovtOfficial: false,
  isSeniorJudicialOfficial: false,
  isSeniorMilitaryOfficial: false,
  isSeniorExecSOC: false,
  isImportantPoliticalPartyOfficial: false,
  isSeniorExecIO: false,
  isMemberOfBOIO: false,
  natureOfPEPId: null,
  pepNatureOfDepartment: null,
  pepNameOfFamilyMember: null,
  pepNameOfCloseAssociate: null,
  pepDesignation: null,
  pepGrade: null,

  w9Name: null,
  w9Address: null,
  w9TIN: null,
  w9SSN: null,
  w9EIN: null,
  isCertify: false,

  countryOfTaxId: null,
  isTINAvailable: false,
  tinNumber: null,
  tinReasonId: null,
  tinReasonDetail: null,

  isReceiveStatememt: false,
  isReinvest: false,
  cellOwnerConsent: false,
  isAgreeTerms: false,

  isFinalSubmit: false,
  applicationStatusId: 0,

  allDocsUploaded: false,
  SalesRepresentativeNameCode: "",
  SalesRepresentativeMobileNumber: "",
};
