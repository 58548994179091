import Api from "../api/api";

export const createInitialInvestmentRequest = async (
  userId: number,
  userApplicationId: number | null,
  fundId: number,
  investmentAmount: number,
  paymentMode: number,
  frontEndLoad: number,
  navApplied: number,
  proofOfPayment: string | null,
  bankId: number | null
) => {
  var route = "investmentrequest/initial";
  var body = {
    userId,
    userApplicationId,
    fundId,
    investmentAmount,
    paymentMode,
    frontEndLoad,
    navApplied,
    proofOfPayment,
    bankId,
  };
  var Data = await Api(route, body, "POST");
  return Data;
};

export const createInvestmentRequest = async (
  userId: number,
  folioNumber: number,
  fundId: number,
  investmentAmount: number,
  paymentMode: number,
  frontEndLoad: number,
  navApplied: number,
  proofOfPayment: string | null,
  bankId: number | null
) => {
  var route = "investmentrequest";
  var body = {
    userId,
    folioNumber,
    fundId,
    investmentAmount,
    paymentMode,
    frontEndLoad,
    navApplied,
    proofOfPayment,
    bankId,
  };
  var Data = await Api(route, body, "POST");
  return Data;
};

export const createRedemptionRequest = async (
  userId: number,
  folioNumber: number,
  fundId: number,
  redemptionAmount: number,
  backEndLoad: number,
  navApplied: number,
  bankID: string,
  bankAccountNo: string
) => {
  var route = "redemptionrequest";
  var body = {
    userId,
    folioNumber,
    fundId,
    redemptionAmount,
    backEndLoad,
    navApplied,
    bankID,
    bankAccountNo,
  };
  var Data = await Api(route, body, "POST");
  return Data;
};

export const createFundToFundTransferRequest = async (
  userId: number,
  folioNumber: number,
  fromFundId: number,
  transferAmount: number,
  fromNavApplied: number,
  fromNumOfUnits: number,
  toFundId: number,
  toNavApplied: number,
  toNumOfUnits: number
) => {
  var route = "fundtransferrequest";
  var body = {
    userId,
    folioNumber,
    fromFundId,
    transferAmount,
    fromNavApplied,
    fromNumOfUnits,
    toFundId,
    toNavApplied,
    toNumOfUnits,
  };
  var Data = await Api(route, body, "POST");
  return Data;
};

export const getFundWisePosition = async (folioNumber: number) => {
  console.log("cloud", process.env.REACT_APP_CLOUD);
  var route =
    `investment/fundwiseposition/${
      process.env.REACT_APP_CLOUD ? process.env.REACT_APP_CLOUD + "/" : ""
    }` + folioNumber;
  var Data = await Api(route, null, "GET");
  return Data;
};

export const getAllFundWisePosition = async (folioNumber: number) => {
  var route = "investment/allfundwiseposition/" + folioNumber;
  var Data = await Api(route, null, "GET");
  return Data;
};

export const getFolioBanks = async (userId: number) => {
  var route = "redemptionrequest/banks/" + userId;

  var Data = await Api(route, null, "GET");
  return Data;
};
