import Api from "../api/api";

export const getAllBanksforUser = async (userId: number) => {
  var route = "UserBank/user/" + userId;
  var Data = await Api(route, null, "GET");
  return Data;
};

export const getUserBankById = async (userbankId: number) => {
  var route = "UserBank/" + userbankId;
  var Data = await Api(route, null, "GET");
  return Data;
};

export const updateUserBank = async (userbankId: number, userBank: any) => {
  var route = "UserBank/" + userbankId;
  var Data = await Api(route, userBank, "PUT");
  return Data;
};

export const addUserBank = async (userBank: any) => {
  var route = "UserBank";
  var Data = await Api(route, userBank, "POST");
  return Data;
};
