import List from "@mui/material/List";
import DownloadIcon from "@mui/icons-material/Download";

import GridDX from "../../components/layout/griddx";

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

const ExploreFunds = () => {
  const listItems = [
    {
      title: "Asan Munafa",
      linkString: "/asset/explorefunds/PQAMC-AsanMunafa.pdf",
    },
    {
      title: "Islamic Stock Fund",
      linkString: "/asset/explorefunds/PQAMC-IslamicStockFund.pdf",
    },
    {
      title: "Khalis Bachat",
      linkString: "/asset/explorefunds/PQAMC-KhalisBachat.pdf",
    },
  ];

  const downloadFile = (fileLink: string) => {
    fetch(fileLink).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = fileLink.split("/").pop() ?? "";
        alink.click();
      });
    });
  };

  return (
    <GridDX container sx={{ width: "100%" }}>
      <GridDX item xs={12}>
        <List sx={{ width: "100%" }}>
          {listItems.map((item, index) => (
            <ListItem
              key={"fund_" + index}
              sx={{ p: 1, backgroundColor: "white", my: 1 }}
              alignItems="flex-start"
            >
              <ListItemButton
                onClick={
                  item.linkString
                    ? () => downloadFile(item.linkString)
                    : undefined
                }
              >
                <ListItemText primary={item.title} />
                <ListItemIcon sx={{ justifyContent: "end" }}>
                  <DownloadIcon color="primary" />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </GridDX>
    </GridDX>
  );
};

export default ExploreFunds;
