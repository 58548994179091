import Api from "../api/api";

export const getTransactionsForFolio = async (folioNumber: number) => {
  var route =
    `transactions/ForFolio/${process.env.REACT_APP_CLOUD ?? ""}/` + folioNumber;

  var Data = await Api(route, null, "GET");
  return Data;
};

export const getPendingTransactionsForFolio = async (folioNumber: number) => {
  var route = "transactions/Pending/ForFolio/" + folioNumber;
  var Data = await Api(route, null, "GET");
  return Data;
};
